<template>
  <v-app>
    <div>
      <div class="row">
        <div class="col-xl-8">
          <h1 class="ml-4 main-header">Client Overview</h1>
        </div>

        <div class="col-xl-2 ml-auto text-right">
          <div class="d-flex ml-auto text-right"></div>
        </div>
      </div>

      <div class="row mb-10">
        <div class="col-12">
          <div class="inf-table pt-8">
            <b-row class="table-top">
              <b-col>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search By Client"
                  @input="searchClient"
                  v-model="searchQuery"
                />
              </b-col>
              <b-col>
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-3">Status</h5>
                  <div style="width: 70%">
                    <select
                      name=""
                      id="status"
                      class="form-control"
                      v-model="inputStatusType"
                    >
                      <option value="All" class="option" selected>All</option>
                      <option value="New" class="option">New</option>
                      <option value="Active" class="option">Active</option>
                      <option value="Deactivated" class="option">
                        Deactivated
                      </option>
                      <option value="Unavailable" class="option">
                        Unavailable
                      </option>
                      <option value="Paused" class="option">Paused</option>
                    </select>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-3">Client Type</h5>
                  <div style="width: 70%">
                    <select
                      name=""
                      id="status"
                      class="form-control"
                      v-model="inputClientType"
                    >
                      <option value="All" class="option" selected>
                        All Clients
                      </option>
                      <option value="P" class="option">
                        Performance Clients only
                      </option>
                      <option value="I" class="option">
                        Influencer Clients only
                      </option>
                    </select>
                  </div>
                </div>
              </b-col>
            </b-row>

            <ag-grid-vue
              class="ag-grid-table ag-theme-material"
              :gridOptions="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              style="width: 100%; height: 500px"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :rowData="clientsArray"
              rowSelection="multiple"
              :animateRows="true"
              :floatingFilter="true"
              @rowClicked="onRowClicked"
              :pagination="true"
              :paginationPageSize="10"
              overlayLoadingTemplate="Loading clients..."
              :overlayNoRowsTemplate="
                !searchData.length ? 'Loading clients...' : 'No Client Found.'
              "
            >
            </ag-grid-vue>
            <span class="rowsPerPage rowsPerpageBtm">
              <b-form-select
                label="Grouped options"
                @change="onPageSizeChanged"
                v-model="selectedNumberOfRows"
                :options="items"
                ><template #first>
                  <b-form-select-option :value="null"
                    >Rows Per Page</b-form-select-option
                  >
                </template></b-form-select
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ApiService from "../../core/services/api.service";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CellRendererAvatars from "@/view/pages/home/Planners/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/home/Planners/CellRendererName.vue";
import CellRendererMATERIALS from "@/view/pages/home/Planners/CellRendererMATERIALS.vue";
import CellRendererCoupon from "@/view/pages/home/Planners/CellRendererCoupon.vue";
import startplan from "@/view/pages/home/Planners/startplan.vue";
import CellRendererManage from "@/view/pages/home/Planners/CellRendererManage.vue";
import CellRendererClientType from "@/view/pages/home/Planners/CellRendererClientType.vue";
import CellRendererStatus from "@/view/pages/home/Planners/CellRendererStatus.vue";
import { AgGridVue } from "ag-grid-vue";
import { bus } from "../../main";

export default {
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
    CellRendererMATERIALS,
    CellRendererCoupon,
    startplan,
    CellRendererManage,
    CellRendererClientType,
    CellRendererStatus,
  },

  data() {
    return {
      date: new Date().toISOString().substring(0, 7),
      calenderDate: new Date().toISOString().substr(0, 7),
      calenderDatePicker: new Date().toISOString().substr(0, 7),
      menu: false,
      rowText: "dsadas",
      modal: false,
      startCompleteDate: null,
      endCompleteDate: null,
      searchData: [],
      selectedNumberOfRows: null,
      items: [5, 10, 25, 50, 100],
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      overlayNoRowsTemplate: "Loading Clients...",
      defaultColDef: {
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 120,
        filter: true,
        resizable: true,
      },
      gridOptions: {
        frameworkComponents: {
          CellRendererAvatars: CellRendererAvatars,
          CellRendererName: CellRendererName,
          CellRendererMATERIALS: CellRendererMATERIALS,
          CellRendererCoupon: CellRendererCoupon,
          CellRendererManage: CellRendererManage,
          CellRendererClientType: CellRendererClientType,
          CellRendererStatus: CellRendererStatus,
        },
        rowHeight: 60,
        columnDefs: [
          {
            headerName: "Client",
            field: "name",
            minWidth: 180,
            sortable: true,

            cellRenderer: (params) => {
              const route = {
                name: "ClientViewSelection",

                params: { id: params.data._id, type: params.data.client_type },
              };

              const img = document.createElement("img");
              img.src = params.data.image;
              img.alt = "No Image";
              cellClass: "CellRenderImg";

              img.style.width = "50px";
              img.style.marginTop = "5px";
              img.style.marginRight = "5px";

              img.style.height = "50px";
              // img.style.width: 80px;
              // borderRadius
              img.style.borderRadius = "14px";
              img.style.border = "2px solid white";
              img.style.boxShadow = "gainsboro 0px 0px 5px";

              const link = document.createElement("a");
              const linkimg = document.createElement("span");
              linkimg.innerHTML = params.data.name;
              linkimg.style.color = "black";
              link.appendChild(img);
              link.appendChild(linkimg);
              link.href = this.$router.resolve(route).href;
              link.addEventListener("click", (e) => {
                e.preventDefault();
                this.$router.push(route);
              });
              return link;
            },
          },
          {
            headerName: "CLIENT TYPE",
            field: "client_type",
            filter: false,
            sortable: true,
            cellRendererFramework: "CellRendererClientType",
            cellStyle: { "white-space": "normal", "line-height": "1px" },
          },
          {
            headerName: "Manage",
            field: "_id",
            filter: false,
            sortable: true,
            cellRendererFramework: "CellRendererManage",
            cellStyle: { "white-space": "normal", "line-height": "1" },
          },
          {
            headerName: "STATUS",
            field: "status",
            filter: false,
            sortable: true,
            cellRendererFramework: "CellRendererStatus",
            cellStyle: { "white-space": "normal", "line-height": "1.5" },
          },
        ],
      },
      isLoading: false,
      clientsArray: [],
      selected_client: null,
      inputStatusType: "All",
      inputClientType: "All",
    };
  },
  created() {
    bus.$on("changeIt", (data) => {
      this.isLoading = true;
      let searchArray = [];
      for (let i = 0; i < this.searchData.length; i++) {
        if (
          this.searchData[i].name.toLowerCase().includes(data.toLowerCase())
        ) {
          searchArray.push(this.searchData[i]);
        }
      }
      this.clientsArray = searchArray;
      this.isLoading = false;
    });
  },
  methods: {
    onPageSizeChanged(newPageSize) {
      this.gridOptions.api.paginationSetPageSize(this.selectedNumberOfRows);
    },
    onRowClicked(row) {
      console.log(row.data);
      this.client_id = row.data._id;
      localStorage.setItem("client_id", this.client_id);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    setColumnFilter(column, val) {
      if (this.gridApi == null) return;
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = {
          type: "equals",
          filter: val,
        };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
      this.typeFilter = this.statusFilter = {
        label: "All",
        value: "all",
      };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi = this.gridOptions.api;
      this.gridApi.setQuickFilter(val);
    },
    async fetch_data() {
      this.isLoading = true;
      console.log(this.isLoading);

      let response = await ApiService.get("/clients");
      let data = response.data.data.map((x) => {
        return {
          ...x,
          status: x.status ? this.capitalizeFirstLetter(x.status) : "",
        };
      });
      this.clientsArray = data;
      this.searchData = data;
      this.isLoading = false;
      console.log(this.isLoading);
    },
    searchClient() {
      this.isLoading = true;
      let searchArray = [];
      for (let i = 0; i < this.searchData.length; i++) {
        if (
          this.searchData[i].name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          searchArray.push(this.searchData[i]);
        }
      }
      this.clientsArray = searchArray;
      this.isLoading = false;
    },
    onSelectStartDate(ctx) {
      this.date = ctx.activeYMD;
    },
    filterClients() {
      this.isLoading = true;
      if (this.inputStatusType == "All" && this.inputClientType == "All") {
        this.clientsArray = this.searchData;
        this.inputStatusType = "All";
        this.inputClientType = "All";
        return;
      }
      this.clientsArray = this.searchData.filter(
        (client) =>
          client.status &&
          client.status.toLowerCase() == this.inputStatusType.toLowerCase() &&
          ((client.client_type &&
            client.client_type.toLowerCase() ==
              this.inputClientType.toLowerCase()) ||
          this.inputClientType == "All"
            ? client.client_type.toLowerCase() == "p&i"
            : "")
      );
      console.log(
        this.clientsArray.length,
        this.clientsArray,
        this.inputStatusType,
        this.inputClientType
      );
      this.isLoading = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  watch: {
    typeFilter(obj) {
      this.setColumnFilter("type", obj.value);
    },
    statusFilter(obj) {
      this.setColumnFilter("img", obj);
    },
    conditionFilter(obj) {
      this.setColumnFilter("condition", obj.value);
    },
    inputStatusType() {
      this.filterClients();
    },
    inputClientType() {
      this.filterClients();
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 1;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        //  this.gridApi.paginationGoToPage(val - 1)
      },
    },
  },
  mounted() {
    // if (localStorage.getItem("reloaded")) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem("reloaded");
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem("reloaded", "1");
    //   location.reload();
    // }
    this.fetch_data();
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.rowText = this.gridOptions.api.showNoRowsOverlay();

    /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
    // if (this.$vs.rtl) {
    //     const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
    //     header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    // }
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.min.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 12px !important;
  font-weight: bold !important;
}

.table-top {
  input,
  select {
    width: 100%;
    height: 40px !important;
    font-size: 1rem !important;
    border: 0.1px solid #eee;
    border-radius: 0.75rem;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    outline: none;
    .option {
      padding: 0.3rem 1rem !important;
      font-size: 1rem !important;
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      outline: none;
    }
  }
  overflow: hidden;
}

.ag-theme-material .ag-ltr .ag-selection-checkbox {
  margin-right: 5 !important;
}
.ag-paging-row-summary-panel {
  display: inline;
}
.rowsPerpageBtm {
  position: absolute;
  bottom: 32px;
  right: 150px;
  width: 80px;
}

.plan-box {
  background-color: #f3fcff;
  min-height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  span:first-of-type {
    display: block;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }

  h1 {
    font-weight: bold;
    color: #000;
    font-size: 4rem;
    margin: 10px 0;
  }

  span:last-of-type {
    display: block;
    text-transform: capitalize;
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }
}

.dropdown-heres {
  background: none !important;
  color: #f64e70 !important;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.planing {
  text-align: center;
  margin: 0 auto;
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 2rem;

  h1 {
    font-weight: bold;
  }

  .sub-title {
    color: #5ebb46;
    font-weight: bold;
  }

  .plan-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ag-theme-material .ag-ltr .ag-cell {
  font-size: 12px;
}

.ag-header-cell-text {
  font-weight: bold;
  color: #000;
  white-space: initial;
  font-size: 11px;
  text-transform: uppercase;
  word-break: break-word;
}

.ag-header-row-floating-filter {
  display: none;
}

.ag-focus-managed {
  height: 70px !important;
  min-height: 70px !important;
}

.ag-paging-panel {
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
}

.ag-theme-material {
  .ag-paging-row-summary-panel {
    display: block !important;
  }
}

.ag-theme-material .ag-paging-button {
  background-color: #f3fcff;
  padding: 5px;
  border-radius: 5px;
  color: #82ceca;
  font-weight: bold;
}

.ag-paging-description {
  color: #82ceca;
}
.rowsPerPage {
  display: inline-block !important;
}
.search-box {
  margin-left: 20px;
}
body {
  font-family: "Poppins" !important;
}
.CellRenderImg {
  border-radius: 20px;
  box-shadow: gainsboro 0px 0px 5px;
}
</style>
