<template>
  <div class="box mt-2">
    <select @change="onChange($event)">
      <option :selected="New" value="New" class="blue">New</option>
      <option :selected="Active" value="Active" class="green">Active</option>
      <option :selected="Paused" value="Paused" class="red">Paused</option>
      <option :selected="Deactivated" value="Deactivated" class="yellow">
        Deactivated
      </option>
      <option :selected="Unavailable" value="Unavailable" class="Unavailable">
        Unavailable
      </option>
    </select>
    <div v-if="New" class="send_icon">
      <inline-svg src="media/svg/icons/Files/icon_share.svg" />
    </div>
    <!-- <p>{{value}}</p> -->
    <!-- :class="[Active ? 'greenParent' : '',Reject ? 'redParent' : '']" -->
  </div>
</template>

<script>
import ApiService from "../../../../core/services/api.service";

export default {
  // name: 'CellRenderDropdown',
  name: "CellRendererDropdown",
  props: ["client_id"],
  data() {
    return {
      Active: Boolean,
      New: Boolean,
      Paused: Boolean,
      Deactivated: Boolean,
      Unavailable: Boolean,
    };
  },
  computed: {},
  mounted() {
    this.value();
  },
  methods: {
    onChange(event, append = false) {
      let APIURL = "/campaigns/status";
      let dataToPost = [];
      let dataTest = {
        client_id: this.$route.params.id,
        campaign_id: this.params.data._id,
        status: event.target.value,
      };
      dataToPost.push(dataTest);
      dataToPost = JSON.stringify(dataToPost);
      console.log(dataToPost);
      // *******change request if Offers-Home Page
      if (this.$route.fullPath.startsWith("/offer-home")) {
        APIURL = "/offers/status";
        dataToPost = [];
        (dataTest = {
          offer_id: this.params.data._id,
          status: event.target.value,
        }),
          dataToPost.push(dataTest);

        console.log(APIURL);
      }
      // *******change request if Performance-Home Page
      if (this.$route.fullPath.startsWith("/performance-home")) {
        APIURL = "/offers/status";
        (dataToPost = {
          client_id: this.$route.params.id,
          campaign_id: this.params.data._id,
          status: event.target.value,
        }),
          console.log(APIURL);
      }
      // *******change request if Influencer-Management Page

      if (this.$route.fullPath.startsWith("/influe")) {
        APIURL = "/campaigns/status";
        let zz = [];

        (dataTest = {
          client_id: this.$route.params.id,
          campaign_id: this.params.data._id,
          status: event.target.value,
        }),
          zz.push(dataTest);
        ApiService.post(APIURL, dataTest).then((response) => {
          console.log("response ", response.data.message);
        });
        // console.log(APIURL);
        // console.log(this.params.data);
      }

      if (this.$route.fullPath.startsWith("/dashboard")) {
        APIURL = "/clients/status";
        dataToPost = [];
        setTimeout(() => {
          (dataTest = {
            client_id: localStorage.getItem("client_id")
              ? localStorage.getItem("client_id")
              : "",
            status: event.target.value,
          }),
            dataToPost.push(dataTest);
          ApiService.post(APIURL, dataToPost).then((response) => {
            console.log("response ", response.data.message);
            localStorage.removeItem("client_id");
          });

          console.log(APIURL);
        }, 500);
      }

      this.$bvToast.toast(
        `Status Updated Successfully To ${event.target.value} `,
        {
          title: "Status Changed",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          variant: "success",
          appendToast: append,
        }
      );
      if (event.target.value == "New") {
        this.New = true;
      } else {
        this.New = false;
      }
    },

    value() {
      if (this.params.data.status == "Active") {
        this.Active = true;
      } else if (this.params.data.status == "Paused") {
        this.Paused = true;
      } else if (this.params.data.status == "Deactivated") {
        this.Deactivated = true;
      } else if (this.params.data.status == "Unavailable") {
        this.Unavailable = true;
      } else if (this.params.data.status == "New") {
        this.New = true;
      }

      // this.Active = this.params.data.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  font-size: inherit;
  background: rgb(89, 158, 89) !important;
  color: white !important;
}
.red {
  font-size: inherit;
  background: #fd397a !important;
  color: white !important;
}
.yellow {
  font-size: inherit;
  background: #f3a430 !important;
  color: white !important;
}
.blue {
  font-size: inherit;
  background: rgb(163, 163, 255) !important;
  color: white;
}
.Unavailable {
  font-size: inherit;
  background: rgb(117, 117, 117) !important;
  color: white;
}
.box select option:focus {
  background: #aaa !important;
}
.box {
  border-radius: 5%;
  padding: 0px;
  margin: 0px;
  height: 3rem;
}

.box select {
  font-size: 1rem !important;
  text-align: center;
  border: 0.1px solid #c3dcf5;
  background-color: #f3f9ff;
  border-radius: 0.5rem;
  padding: 0.3rem;
  font-size: 1rem;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  width: 88px;
}

// .box::before {
//   content: "\f13a";
//   position: relative;
//   font-family: FontAwesome;
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 20%;
//   height: 100%;
//   text-align: center;
//   font-size: 1rem;
//   line-height: 1rem;
//   color: rgba(255, 255, 255, 0.5);
//   background-color: rgba(255, 255, 255, 0.1);
//   pointer-events: none;
// }

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
.send_icon {
  position: absolute;
  margin: auto;
  // background-color: red;
  height: 3rem;
  width: 3rem;
  margin-left: 0.2rem;
  text-align: center;
  display: inline-block;
  // color: blue;
}
svg {
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
}
.send_icon:hover {
  transform: scale(1.2);
}
</style>
