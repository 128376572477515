<template>
  <b-row class="d-flex justify-content-center text-center">
    <b-col class="col-6 col-md-3" role="button" @click="selectType(3)">
      <b-avatar
        src="media/images/offers.png"
        v-b-tooltip.hover.bottom="'Manage Offers'"
        text="dasdasd"
        class="selection-icon"
      >
      </b-avatar>
    </b-col>

    <b-col class="col-6 col-md-3" role="button" @click="selectType(4)">
      <b-avatar
        src="media/images/coupons.png"
        v-b-tooltip.hover.bottom="'Manage Coupons'"
        text="dasdas"
        class="selection-icon"
      >
      </b-avatar>
    </b-col>

    <b-col class="col-6 col-md-3" role="button" @click="selectType(5)">
      <b-avatar
        src="media/images/payout.png"
        v-b-tooltip.hover.bottom="'Manage Payouts'"
        text="dasdas"
        class="selection-icon"
      >
      </b-avatar>
    </b-col>

    <b-col class="col-6 col-md-3" role="button" @click="selectType(6)">
      <b-avatar
        src="media/images/cancellation.png"
        v-b-tooltip.hover.bottom="'Manage Cancellation'"
        text="Manage Cancellation"
        class="selection-icon"
      >
      </b-avatar>
    </b-col>
  </b-row>
</template>

<script>
export default {
  // name: 'CellRendererManage',
  name: "CellRendererManage",
  data() {
    return {
      Active: false,
      Reject: false,
      Confirm: false,
      New: false,
    };
  },
  computed: {},
  mounted() {
    console.log("Parminder", this.params.value);
  },
  methods: {
    selectType(type) {
      var id = this.params.value;
      if (type == 1) {
        this.$router.push({ name: "performancehome", params: { id: id } });
      }
      if (type == 2) {
        this.$router.push({ name: "influencermanagement", params: { id: id } });
        // console.log("Management")
      }

      if (type == 3) {
        this.$router.push({ name: "offerhome", params: { id: id } });
      }

      if (type == 4) {
        this.$router.push({ name: "coupon-management", params: { id: id } });
      }

      if (type == 5) {
        this.$router.push({ name: "payout", params: { id: id } });
      }

      if (type == 6) {
        this.$router.push({ name: "cancellation-rule", params: { id: id } });
      }

      this.selected = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.selection-icon {
  height: 32px;
  min-width: 32px;
}
.green {
  font-size: inherit;
  background: rgb(89, 158, 89) !important;
  color: white !important;
}
.red {
  font-size: inherit;
  background: #fd397a !important;
  color: white !important;
}
.yellow {
  font-size: inherit;
  background: #f3a430 !important;
  color: white !important;
}
.blue {
  font-size: inherit;
  background: rgb(163, 163, 255) !important;
  color: white;
}
.box select option:focus {
  background: #aaa !important;
}
.box {
  border-radius: 5%;
  padding: 0px;
  margin: 0px;
  height: 3rem;
}

.box select {
  font-size: 1rem !important;
  text-align: center;
  border: 0.1px solid #c3dcf5;
  background-color: #f3f9ff;
  border-radius: 0.5rem;
  padding: 0.3rem;
  font-size: 1rem;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

// .box::before {
//   content: "\f13a";
//   position: relative;
//   font-family: FontAwesome;
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 20%;
//   height: 100%;
//   text-align: center;
//   font-size: 1rem;
//   line-height: 1rem;
//   color: rgba(255, 255, 255, 0.5);
//   background-color: rgba(255, 255, 255, 0.1);
//   pointer-events: none;
// }

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
.send_icon {
  position: absolute;
  margin: auto;
  // background-color: red;
  height: 3rem;
  width: 3rem;
  margin-left: 0.2rem;
  text-align: center;
  display: inline-block;
  // color: blue;
}
svg {
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
}
.send_icon:hover {
  transform: scale(1.2);
}
</style>
