<template>
  <div>
    <div v-if="params.value == 'P' || params.value == 'P&I'" class="mt-2">
      <a @click="redirectToPage(1)">
        <b-badge pill variant="secondary">PERFORMANCE</b-badge>
      </a>
    </div>
    <br />
    <br />
    <br />
    <div v-if="params.value == 'I' || params.value == 'P&I'">
      <a @click="redirectToPage(2)">
        <b-badge pill variant="success">INFLUENCER</b-badge>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // name: 'CellRendererManage',
  name: "CellRendererManage",
  data() {
    return {
      Active: false,
      Reject: false,
      Confirm: false,
      New: false,
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    redirectToPage(data) {
      var id = this.params.data._id;
      var type = this.params.data.client_type;
      if (data == 1) {
        this.$router.push({ name: "performancehome", params: { id: id , type: type} });
      }
      if (data == 2) {
        this.$router.push({ name: "influencermanagement", params: { id: id, type: type } });
      }
      this.selected = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-success {
  color: #ffffff;
  background-color: #34d07b;
}
.badge-secondary {
  color: #ffffff;
  background-color: #2885e9;
}
</style>
